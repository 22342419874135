var video = document.querySelector('#video')
var source = document.querySelector('#source')

let btnFull = document.querySelector(".full__screen-btn")
btnFull.addEventListener('click', (e) => {
  var element = document.querySelector("body");
  // make the element go to full-screen mode
  element.requestFullscreen()
    .then(function () {
      // element has entered fullscreen mode successfully
    })
    .catch(function (error) {
      // element could not enter fullscreen mode
    });
})


const slideConfig = {
  direction: 'vertical',
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
}
const swiper = new Swiper('.my-swiper', slideConfig)
swiper.on('slideChange', function () {
  console.log('Slide changed ', swiper.activeIndex)

  let screens = [
    {
      'index': 0,
      'video': '001',
    },
    {
      'index': 1,
      'video': '006',
    },
    {
      'index': 2,
      'video': '002',
    },
    {
      'index': 3,
      'video': '009',
    },
    {
      'index': 4,
      'video': '010',
    },
    {
      'index': 5,
      'video': '003',
    },
    {
      'index': 6,
      'video': '004',
    },
    {
      'index': 7,
      'video': '005',
    },
    {
      'index': 9,
      'video': '007',
    },
    {
      'index': 10,
      'video': '008',
    },
    {
      'index': 11,
      'video': '011',
    },
  ]

  for (let i = 0; i < screens.length; i++) {
    if (swiper.activeIndex == screens[i].index) {
      source.setAttribute('src', `./media/${screens[i].video}.mp4`)
      source.setAttribute('type', 'video/mp4')
      video.load()
      video.play()
    }
  }




})

